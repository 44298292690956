import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import BlogPost from '../templates/blog-post';
import { graphql } from 'gatsby';
import CodeExample from '../components/CodeExample';
import Note from '../components/Note';
import Code from '../components/code';
import LittleImg from '../components/LittleImg';
export const _frontmatter = {
  "title": "My Favorite Tools",
  "date": "2020-02-17",
  "promo": "grids",
  "description": "My goldmine of favorite web dev tools",
  "color": "#75ea8d"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = BlogPost;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`In college I took an oil painting class. I showed up on the first day with a set of cheap brushes I'd picked up from the campus bookstore. My professor took one glance over his big mustache and said "Painting is hard enough without crappy tools, kid". He was right, and what he said has stuck with me to this day.`}</p>
    <p>{`The tools you use help you create more and better things than you could have on your own. Your tools empower you. They deserve your fierce loyalty... right up until the second you find something `}<em parentName="p">{`better`}</em>{`!`}</p>
    <p>{`In that spirit, here's my update to date list of favorite tools for:`}</p>
    <h2>{`Brainstorming`}</h2>
    <p><a parentName="p" {...{
        "href": "https://whimsical.com?ref=dg1219"
      }}>{`Whimsical`}</a>{` is my latest favorite suite of tools. It's got great mindmaps, sticky notes (kanban board on steroids), flowcharts and wireframes. It's one of only four tabs I keep pinned in my browser because I use it so frequently. I use it to brainstorm ideas for my biz. I especially love their flexible sticky notes when I've got a workflow that isn't exactly linear. The ability to create a `}<em parentName="p">{`stack`}</em>{` of cards anywhere is powerful. `}<a parentName="p" {...{
        "href": "https://whimsical.com?ref=dg1219"
      }}>{`Whimsical`}</a>{` gets out of the way and is a perfect fit for creative thinking.`}</p>
    <p>{`Here's a `}<a parentName="p" {...{
        "href": "/post/state-machines-in-react/"
      }}>{`state machine`}</a>{` flowchart I made in Whimsical when thinking through one of the boss fight levels in my `}<a parentName="p" {...{
        "href": "https://serviceworkies.com"
      }}>{`Service Workies`}</a>{` game.`}</p>
    <p><img parentName="p" {...{
        "src": "/img/sw-statemachine-flow.jpg",
        "alt": "state machine flow"
      }}></img></p>
    <h2>{`Arranging app windows`}</h2>
    <p>{`Life's too short to arrange your own windows. I've been using `}<a parentName="p" {...{
        "href": "https://manytricks.com/moom?coupon=GEDD-SKI10DISC"
      }}>{`Moom`}</a>{` for over five years now and it gets the job done better than any app other I've tried. It has excellent keyboard shortcut support also. I've got it setup so cmd+shift+space triggers Moom, then I can arrow key left to dock a window the the left, or right for right, or hit space again to go fullscreen. Moom also has some smart external monitor support — you can tell it to arrange your windows in a certain way when you plug/unplug your monitor(s).`}</p>
    <LittleImg width="200px" src="/img/moom.png" mdxType="LittleImg" />
    <h2>{`Taking notes`}</h2>
    <p>{`I was a long-time `}<a parentName="p" {...{
        "href": "http://evernote.com/"
      }}>{`Evernote`}</a>{` user but have recently found myself using `}<a parentName="p" {...{
        "href": "https://www.notion.so/?r=a8985d7a66844c40b303b28c5b994232"
      }}>{`Notion`}</a>{` in its place. It's very flexible in the kinds of content it can store. I've got pages with data tables, kanban boards, and embedded codepens all in one place. `}<a parentName="p" {...{
        "href": "https://www.notion.so/?r=a8985d7a66844c40b303b28c5b994232"
      }}>{`Notion`}</a>{` is frequently used as a `}<em parentName="p">{`team wiki`}</em>{` of sorts — a central place for a team's collective knowledge/learnings. I use it on my own as well and enjoy it quite a bit. In a `}<a parentName="p" {...{
        "href": "https://www.notion.so/What-s-New-157765353f2c4705bd45474e5ba8b46c"
      }}>{`recent update`}</a>{` they just added support for embedding `}<a parentName="p" {...{
        "href": "https://whimsical.com?ref=dg1219"
      }}>{`Whimsical`}</a>{` — two favorites are now friends!`}</p>
    <p>{`I used Notion extensively for the outline in `}<a parentName="p" {...{
        "href": "https://serviceworkies.com"
      }}>{`Service Workies`}</a>{`, and was able to share just this page with my friends at `}<a parentName="p" {...{
        "href": "https://web.dev"
      }}>{`Google`}</a>{` who sponsored the game.`}</p>
    <p><img parentName="p" {...{
        "src": "/img/service-workies-outline.jpg",
        "alt": "Service Workies Outline"
      }}></img></p>
    <p>{`For freestyle, handwritten notes and diagrams I still prefer using the iPad pencil with a lovely little app called `}<a parentName="p" {...{
        "href": "https://concepts.app/en/"
      }}>{`Concepts`}</a>{`. For drawing & sketching there's still nothing better than `}<a parentName="p" {...{
        "href": "https://procreate.art/"
      }}>{`Procreate`}</a>{`.`}</p>
    <p>{`I'm also beginning to appreciate bullet journaling in an `}<a parentName="p" {...{
        "href": "/post/favorite-gear/#Favorite-Notebook"
      }}>{`analogue notebook`}</a>{` like in the good old days.`}</p>
    <h2>{`Storing passwords`}</h2>
    <p>{`These days using a password manager is a must. It's unsafe to do almost anything else. `}<a parentName="p" {...{
        "href": "https://1password.com/"
      }}>{`1Password`}</a>{` is extremely good. I've watched it just get better and better over the past decade. The way it integrates now with your mobile OS is the icing on the cake. The user experience is very polished and I'm `}<a parentName="p" {...{
        "href": "/post/polish-makes-perfect/"
      }}>{`all about that`}</a>{`.`}</p>
    <h2>{`Measuring pixels`}</h2>
    <p>{`When building a CSS layout these days you have to think fluid/responsive mindset. But I can't even count how many times a day I need to take measurements or check alignments. The browser dev tools have some `}<em parentName="p">{`minimal`}</em>{` tools around this, but I've fallen completely in love with `}<a parentName="p" {...{
        "href": "https://gumroad.com/a/836662387"
      }}>{`PixelSnap`}</a>{`. It lets me take multiple measurements quickly, leave them there if I wish, change some code and refresh the page, and see if my the resulting measurements are correct. It's completely awesome. The developer Paweł has listened to my feedback and made `}<a parentName="p" {...{
        "href": "https://gumroad.com/a/836662387"
      }}>{`PixelSnap`}</a>{` `}<em parentName="p">{`extremely`}</em>{` good for the web dev workflow. I couldn't be happier with it.`}</p>
    <LittleImg width="500px" src="/img/pixelsnap.jpg" mdxType="LittleImg" />
    <h2>{`Taking screenshots`}</h2>
    <p>{`I take a lot of screenshots - for sharing in my articles and on `}<a parentName="p" {...{
        "href": "https://twitter.com/geddski"
      }}>{`twitter`}</a>{`. For this, `}<a parentName="p" {...{
        "href": "https://techsmith.z6rjha.net/c/2085716/476802/5161"
      }}>{`Snagit`}</a>{` has been an indispensible tool — way better than the built-in Mac one that I can never remember how to use. I have Snagit bound to `}<a parentName="p" {...{
        "href": "https://twitter.com/geddski/status/1229552173442195458"
      }}>{`hyper`}</a>{`+i to make it even easier. Snagit lets you mark up your screenshots with text/arrows, edit/crop, and somehow even adjust the position of things in the screenshot, which feels like black magic.`}</p>
    <p><a parentName="p" {...{
        "href": "https://techsmith.z6rjha.net/c/2085716/476802/5161"
      }}>{`Snagit`}</a>{` keeps track of all your screenshots too. Here's what it looks like as I work on this post:`}</p>
    <p><img parentName="p" {...{
        "src": "/img/snagit.jpg",
        "alt": "Snagit"
      }}></img></p>
    <h2>{`Working with Git`}</h2>
    <p>{`I'm comfortable with Git on the command line, but sometimes a nice GUI makes for a much better workflow. My editor (VSCode) has a built-in Git GUI but it doesn't come close to `}<a parentName="p" {...{
        "href": "https://www.git-tower.com?coupon=geddski"
      }}>{`Tower`}</a>{` that I've used and loved for years. I've prevented checking in many a `}<inlineCode parentName="p">{`console.log`}</inlineCode>{` thanks to the visual commit preview. It makes working with branches fast and easy, and the search feature is quite good.`}</p>
    <LittleImg width="373px" src="/img/git-tower.jpg" mdxType="LittleImg" />
    <p>{`I've also recently added the `}<a parentName="p" {...{
        "href": "https://github.com/cli/cli"
      }}>{`Github CLI`}</a>{` to my workflow as it's the best thing there is currently for working with pull requests locally. Tower has `}<em parentName="p">{`some`}</em>{` pull request functionality but it's not caught up yet to Github CLI.`}</p>
    <h2>{`Comparing diffs`}</h2>
    <p>{`I used to panic when I'd see merge conflicts. If you've ever left a branch alive too long you know the feeling. I'm starting to think perhaps that feeling of panic is just a sign that I don't have the right tool yet. Since discovering and getting familiar with `}<a parentName="p" {...{
        "href": "https://www.kaleidoscopeapp.com/"
      }}>{`Kaleidoscope`}</a>{` there's no diff that can scare me. I especially love their three-way diff, that shows the two changesets on the sides and the (editable) merge results in the middle. It lets you go through each change one at a time and decide what to do with it. You can configure Tower to use Kaleidoscope as your diff tool so whenever Tower detects a conflict, it will open the file for you in Kaleidoscope for you to resolve.`}</p>
    <p><img parentName="p" {...{
        "src": "/img/kaleidoscope.png",
        "alt": "Kaleidoscope diffs"
      }}></img></p>
    <h2>{`Managing browser tabs`}</h2>
    <p>{`As I work, my open browser tabs seem to multiply like `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Tribble"
      }}>{`tribbles`}</a>{`. I used to get completely overwhelmed by the sheer number of them until I discovered `}<a parentName="p" {...{
        "href": "https://workona.com?ref=geddski"
      }}>{`Workona`}</a>{`. It's a powerful Chrome extension (Firefox and Edge coming soon I hear) that lets you organize your tabs into `}<em parentName="p">{`workspaces`}</em>{`. Each workspace has its own set of tabs that you need. When I'm writing an article like I am now, I switch to my `}<em parentName="p">{`Blog`}</em>{` workspace. Workona closes everything but the four tabs I need. If I get a bug report for `}<a parentName="p" {...{
        "href": "https://gridcritters.com"
      }}>{`Grid Critters`}</a>{` I'll switch to my `}<em parentName="p">{`Grid Critters`}</em>{` workspace and I'm ready to go. `}<a parentName="p" {...{
        "href": "https://workona.com?ref=geddski"
      }}>{`Workona`}</a>{` is perfect and I can't believe it's free. If they ever launch a pro plan I'll be the first to sub.`}</p>
    <p>{`Here's what it looks like in my Blog workspace:`}</p>
    <p><img parentName="p" {...{
        "src": "/img/workona.jpg",
        "alt": "Workona tab manager"
      }}></img></p>
    <h2>{`Designing UI`}</h2>
    <p>{`My first design tool love was Fireworks (RIP). When that died I tried to switch to Illustrator but it never felt great. Then Sketch came along and was my go to for a lot of years. Most recently `}<a parentName="p" {...{
        "href": "https://figma.com?ref=geddski"
      }}>{`Figma`}</a>{` was released and after making my new logo in it I was hooked. The navigation (zoom/pan/etc) alone is flawless and has ruined other apps like Photoshop for me. It's very fast even when there are a ton of assets on a page. It's a paragon of the possibilities of `}<a parentName="p" {...{
        "href": "https://www.figma.com/blog/webassembly-cut-figmas-load-time-by-3x/"
      }}>{`compiling to the web`}</a>{`.`}</p>
    <h2>{`Editing code`}</h2>
    <p>{`No code editor beats `}<a parentName="p" {...{
        "href": "https://code.visualstudio.com/"
      }}>{`VSCode`}</a>{` these days. The app is fast, the plugin/theme ecosystem is strong, and it's highly customizable. For example I recently made its terminal panes `}<a parentName="p" {...{
        "href": "https://twitter.com/geddski/status/1227736138837708800?s=20"
      }}>{`behave just like code tabs`}</a>{` and it was a piece of cake. I love that whenever there's a tiny bit of friction in my workflow, I can often eliminate it through customization. `}<a parentName="p" {...{
        "href": "https://code.visualstudio.com/"
      }}>{`VSCode`}</a>{` is the gift that keeps on giving.`}</p>
    <h2>{`Looking at code`}</h2>
    <p>{`My favorite theme as of late is `}<a parentName="p" {...{
        "href": "https://marketplace.visualstudio.com/items?itemName=juanmnl.vscode-theme-1984"
      }}>{`1984 Unbolded`}</a>{` with the clean `}<a parentName="p" {...{
        "href": "https://github.com/i-tu/Hasklig"
      }}>{`Hasklig`}</a>{` font. So much purple! Gosh dang I love purple. It doesn't get much prettier than this.`}</p>
    <p>{`You see this styled blog but as I write I get to look at this beauty:`}</p>
    <p><img parentName="p" {...{
        "src": "/img/theme.jpg",
        "alt": "current theme"
      }}></img></p>
    <h2>{`Picking colors`}</h2>
    <p>{`The browser devtools have really good color pickers these days, but they only work in the browser. So my color picker is the one in `}<a parentName="p" {...{
        "href": "https://xscopeapp.com/"
      }}>{`xScope`}</a>{`. I like that it stores the colors I've picked recently to save me some work. And I like that I can easily switch from hex to rgb. I don't really use all the rest of xScope's tools though, so I am on the lookout for a better/cheaper standalone color picker. If you love yours please let me know!`}</p>
    <h2>{`Setting up a new computer`}</h2>
    <p>{`Setting up a new computer is a pain, but I've automated most of it using `}<a parentName="p" {...{
        "href": "https://github.com/Homebrew/homebrew-bundle"
      }}>{`Brew Bundle`}</a>{`. In a single `}<inlineCode parentName="p">{`Brewfile`}</inlineCode>{` I list all the tools & apps (even App Store ones!), and fonts that I use. Running `}<inlineCode parentName="p">{`brew bundle`}</inlineCode>{` sets up a brand new computer in minutes. It just takes a bit of discipline as you go to add things to your `}<inlineCode parentName="p">{`Brewfile`}</inlineCode>{` instead of manually installing them with `}<inlineCode parentName="p">{`brew install`}</inlineCode>{`. But if you're a lazy sack you can just run the `}<inlineCode parentName="p">{`brew bundle dump`}</inlineCode>{` command to create a new `}<inlineCode parentName="p">{`Brewfile`}</inlineCode>{` that lists everything you have currently installed. But I prefer to keep my setup clean and intentional. Here's my current `}<a parentName="p" {...{
        "href": "https://gist.github.com/geddski/84423a471bd1cf739afe12ee25554b03"
      }}>{`Brewfile`}</a>{` in case you're curious what else I use.`}</p>
    <h2>{`Global keyboard shortcuts`}</h2>
    <p>{`I use `}<a parentName="p" {...{
        "href": "https://pqrs.org/osx/karabiner/"
      }}>{`Karabiner-Elements`}</a>{` for custom keyboard shortcuts for my `}<a parentName="p" {...{
        "href": "https://gedd.ski/post/favorite-gear/#Favorite-Keyboard"
      }}>{`ergodox`}</a>{`. There's a similar program for Windows called `}<a parentName="p" {...{
        "href": "https://www.autohotkey.com/"
      }}>{`AutoHotkey`}</a>{` that I may or may not have used to cheat in Starcraft2 as a kid.`}</p>
    <h2>{`Deleting old apps`}</h2>
    <p>{`Windows has an easy way of uninstalling most things, but for some reason macOS doesn't. So I use `}<a parentName="p" {...{
        "href": "https://freemacsoft.net/appcleaner/"
      }}>{`App Cleaner`}</a>{` when I want to get rid of an app and all its files. We don't need that junk lying around now, do we!`}</p>
    <LittleImg width="400px" src="/img/app-cleaner.jpg" mdxType="LittleImg" />
    <h2>{`3D modeling`}</h2>
    <p>{`This one is more of a hobby tool for me, but `}<a parentName="p" {...{
        "href": "https://www.autodesk.com/products/fusion-360/overview"
      }}>{`Fusion 360`}</a>{` blows my mind. I use mainly for designing and 3D printing things around the house, as well as custom board game accessories cause I'm a nerd like that. I've written before about how our industry could `}<a parentName="p" {...{
        "href": "https://twitter.com/geddski/status/1228789588832899072"
      }}>{`learn a lot`}</a>{` from Fusion's advanced editing/timeline tools.`}</p>
    <p>{`Here are the `}<a parentName="p" {...{
        "href": "https://www.thingiverse.com/thing:3962792"
      }}>{`spacers`}</a>{` I designed for my favorite card game `}<a parentName="p" {...{
        "href": "https://www.fantasyflightgames.com/en/products/marvel-champions-the-card-game/"
      }}>{`Marvel Champions`}</a>{`.`}</p>
    <p><img parentName="p" {...{
        "src": "/img/fusion-360.jpg",
        "alt": "Fusion 360"
      }}></img></p>
    <h2>{`Finding things`}</h2>
    <p>{`I've used `}<a parentName="p" {...{
        "href": "https://www.alfredapp.com/"
      }}>{`Alfred`}</a>{` since my very first Mac and it's been great. They've added some neat features like Workflows and quick calculators. But I mainly use it as a fast search and app opener.`}</p>
    <h2>{`Tracking pomodoros`}</h2>
    <p>{`Pomodoros help me do bursts of focused work. The most fun app I've found is `}<a parentName="p" {...{
        "href": "https://www.forestapp.cc/"
      }}>{`Forest`}</a>{` which grows adorable little trees while you work. I alternate between that and `}<a parentName="p" {...{
        "href": "http://focustimeapp.com/"
      }}>{`Focus Time`}</a>{` to keep me on track.`}</p>
    <p><strong parentName="p">{`Did I miss anything awesome?`}</strong></p>
    <p>{`I'm always trying new tools and will keep this list up to date. If you've got something you love please let me know!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      